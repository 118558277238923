import {
  EventCard,
  Game,
  GameCategory,
  GameCompetitor,
  GameMode,
  GamePeriod,
  GameType,
  GetSportEntityState,
  League,
  LeagueSearchQueryType,
  LeagueSearchResultGroupedBySportAndGameCategory,
  Sport,
} from '@arland-bmnext/api-data'
import { MarketTypeCategoryDTO, MarketTypeDTO } from '@arland-bmnext/webapps-api-data'
import { AxiosClient } from './axiosclient'
import useSWRImmutable from 'swr/immutable'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus, useOddsClient } from './api-client'
import useSWR, { KeyedMutator } from 'swr'
import { PagedResult } from './api-client/core'
import { buildSessionData, Session } from './session'
import { sortArray } from '../util/array'
import { IncomingMessage } from 'http'
import dayjs from 'dayjs'

export const useUpcomingGames = (
  languageId: number,
  startDate: string,
  endDate: string,
  sportId: number = null,
  marketTypeIds: number[] = [],
  pageNumber: number = 0,
  pageSize: number = 8,
): { upcomingGames: Game[]; isValidating: boolean; mutateUpcomingGames: KeyedMutator<PagedResult<Game>> } => {
  let query = '?'
  query += 'startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&languageId=' + languageId
  query += '&gameMode=' + GameMode.PreMatch
  query += '&pageNumber=' + pageNumber
  query += '&pageSize=' + pageSize
  if (sportId != null) query += '&sportId=' + sportId
  if (marketTypeIds?.length > 0) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, error, isValidating, mutate } = useSWR<PagedResult<Game>>(
    languageId && sportId ? `/api/odds/games${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 5 * 60 * 1000, // 5min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { upcomingGames: data?.items, isValidating, mutateUpcomingGames: mutate }
}

export const useSportsById = (sportIds: number[], languageId: number): { sports: Sport[]; isValidating: boolean } => {
  let query = '?'
  if (sportIds?.length > 0) query += '&sportIds=' + sportIds.join(',')
  query += '&languageId=' + languageId

  const { data, error, isValidating } = useSWR<Sport[]>(
    sportIds ? `/api/odds/sports-by-id${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 5 * 60 * 1000, // 5min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { sports: data, isValidating }
}

export const useSportsWithTopGames = (languageId: number) => {
  let query = '?'
  query += 'startDate=' + dayjs(new Date()).format('YYYY-MM-DD')
  query += '&endDate=' + dayjs(new Date()).add(10, 'years').format('YYYY-MM-DD')
  query += '&languageId=' + languageId
  query += '&state=' + GetSportEntityState.Bettable
  query += '&isTop=' + true

  const { data, error, isValidating } = useSWR<Sport[]>(languageId ? `/api/scl/sports${query}` : null, defaultFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 15 * 60 * 1000, // 15min
  })

  handleHttpErrorStatus(error?.status)

  return { sportsWithTopGames: data, isValidating }
}

export const useTopGames = (
  languageId: number,
  sportId: number,
  marketTypeIds: number[] = [],
  pageNumber: number = 0,
  pageSize: number = 7,
): { topGames: Game[]; isValidating: boolean; mutateTopGames: KeyedMutator<PagedResult<Game>> } => {
  let query = '?'
  query += 'startDate=' + dayjs(new Date()).format('YYYY-MM-DD')
  query += '&endDate=' + dayjs(new Date()).add(10, 'years').format('YYYY-MM-DD')
  query += '&languageId=' + languageId
  query += '&sportId=' + sportId
  query += '&gameMode=' + GameMode.PreMatch
  query += '&onlyTop=' + true
  query += '&pageNumber=' + pageNumber
  query += '&pageSize=' + pageSize
  if (marketTypeIds?.length > 0) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, error, isValidating, mutate } = useSWR<PagedResult<Game>>(
    languageId && sportId ? `/api/odds/games${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 15 * 60 * 1000, // 15min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { topGames: data?.items, isValidating, mutateTopGames: mutate }
}

export type LeagueGames = {
  items: Game[]
  totalCount: number
  gameCategoryId: number
  gameCategoryName: string
  gameCategoryShortSign: string
  leagueName: string
  sportId: number
  sportName: string
  sportShortSign: string
}
export const useLeagueGames = (
  id: number,
  languageId: number,
  startDate: string,
  endDate: string,
  marketTypeIds: number[],
  gameMode: GameMode,
  onlyTop: boolean = false,
  pageNumber: number = 0,
  pageSize: number = 30,
): { leagueGames: LeagueGames; isValidating: boolean; mutateLeagueGames: KeyedMutator<LeagueGames> } => {
  let query = '?'
  query += 'leagueId=' + id
  query += '&languageId=' + languageId
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&gameMode=' + gameMode
  query += '&onlyTop=' + onlyTop
  query += '&pageNumber=' + pageNumber
  query += '&pageSize=' + pageSize
  if (marketTypeIds) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, error, isValidating, mutate } = useSWR<LeagueGames>(
    id && languageId ? `/api/odds/games-by-leagueid${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 15 * 60 * 1000, // 15min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { leagueGames: data, isValidating, mutateLeagueGames: mutate }
}

export const getLeagueGames = async (
  req: IncomingMessage,
  session: Session,
  leagueId: number,
  startDate: string,
  endDate: string,
  marketTypeIds: number[],
  gameMode: GameMode,
  onlyTop: boolean = false,
  pageNumber: number = 0,
  pageSize: number = 30,
) => {
  const oddsClient = useOddsClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const languageId = session.get('languageId')
  const sessionData = buildSessionData(req, sessionId, token)

  const games = await oddsClient.games.getGamesByLeagueId(
    sessionData,
    leagueId,
    languageId,
    startDate,
    endDate,
    marketTypeIds,
    gameMode,
    onlyTop,
    pageNumber,
    pageSize,
  )

  games.items = sortArray(games.items, 'startDate', 'asc')

  return games
}

export type ExtendedGame = Game & {
  gameCategoryId?: number
  gameCategoryName?: string
  gameCategoryShortSign?: string
  leagueName?: string
  sportId?: number
  sportName?: string
  sportShortSign?: string
}
export const useGame = (
  id: number,
  languageId: number,
  marketTypeIds?: number[],
): { game: ExtendedGame; isValidating: boolean; mutateGame: KeyedMutator<ExtendedGame> } => {
  let query = '?'
  query += 'gameId=' + id
  query += '&languageId=' + languageId
  if (marketTypeIds) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, error, isValidating, mutate } = useSWR<ExtendedGame>(
    id && languageId ? `/api/odds/game${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 1000, // 1min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { game: data, isValidating, mutateGame: mutate }
}

export const getGameById = async (req: IncomingMessage, session: Session, gameId): Promise<Game> => {
  const oddsClient = useOddsClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const languageId = session.get('languageId')
  const sessionData = buildSessionData(req, sessionId, token)

  return await oddsClient.games.getGameById(sessionData, gameId, languageId)
}

export const useGamesById = (
  gameIds: number[],
  languageId: number,
  marketTypeIds?: number[],
): { games: ExtendedGame[]; isValidating: boolean; mutateGames: KeyedMutator<ExtendedGame[]> } => {
  if (gameIds == null || gameIds.length == 0) return { games: undefined, isValidating: false, mutateGames: undefined }
  let query = '?'
  query += 'gameIds=' + gameIds.join(',')
  query += '&languageId=' + languageId
  if (marketTypeIds) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, error, isValidating, mutate } = useSWR<ExtendedGame[]>(
    gameIds && languageId ? `/api/odds/games-by-id${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 1000, // 1min
    },
  )

  handleHttpErrorStatus(error?.status)
  return { games: data, isValidating, mutateGames: mutate }
}

export const useLeague = (
  id: number,
  languageId: number,
): { league: League; isValidating: boolean; mutateLeague: KeyedMutator<League> } => {
  let query = '?'
  query += 'leagueId=' + id
  query += '&languageId=' + languageId

  const { data, error, isValidating, mutate } = useSWR<League>(
    id && languageId ? `/api/odds/league${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 24 * 60 * 60 * 1000, // 24h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { league: data, isValidating, mutateLeague: mutate }
}

export const getLeagueById = async (req: IncomingMessage, session: Session, leagueId) => {
  const oddsClient = useOddsClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const languageId = session.get('languageId')
  const sessionData = buildSessionData(req, sessionId, token)

  return await oddsClient.leagues.getLeagueById(sessionData, leagueId, languageId)
}

export const useGameCompetitors = (
  gameId: number,
): {
  gameCompetitors: GameCompetitor[]
  isValidating: boolean
  mutateGameCompetitors: KeyedMutator<GameCompetitor[]>
} => {
  let query = '?'
  query += 'gameId=' + gameId

  const { data, error, isValidating, mutate } = useSWR<GameCompetitor[]>(
    gameId ? `/api/odds/game-competitors${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 60min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { gameCompetitors: data, isValidating, mutateGameCompetitors: mutate }
}

export const fullTextLeagueSearch = async (
  languageId: number,
  fulltext: string,
  queryType: LeagueSearchQueryType = LeagueSearchQueryType.OnlyLeagues,
  pageNumber?: number,
  pageSize?: number,
  marketTypeIds?: number[],
): Promise<LeagueSearchResultGroupedBySportAndGameCategory> => {
  let query = '?'
  query += 'languageId=' + languageId
  query += '&fulltext=' + encodeURIComponent(fulltext)
  query += '&queryType=' + queryType
  if (pageNumber) query += '&pageNumber=' + pageNumber
  if (pageSize) query += '&pageSize=' + pageSize
  if (marketTypeIds) query += '&marketTypeIds=' + marketTypeIds.join(',')

  return await AxiosClient.get<LeagueSearchResultGroupedBySportAndGameCategory>(`/api/odds/league-search${query}`)
}

export const useBettableSports = (
  startDate: string,
  endDate: string,
  languageId: number,
): { bettableSports: Sport[]; isValidating: boolean } => {
  let query = '?'
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&languageId=' + languageId

  const { data, error, isValidating } = useSWR<Sport[]>(
    languageId ? `/api/odds/bettable-sports${query}` : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 15 * 60 * 1000, // 15min
    },
  )

  handleHttpErrorStatus(error?.status)

  return { bettableSports: data, isValidating }
}

export const getBettableCategories = async (
  sportId: number,
  startDate: string,
  endDate: string,
  languageId: number,
): Promise<GameCategory[]> => {
  let query = '?'
  query += 'sportId=' + sportId
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&languageId=' + languageId

  return await AxiosClient.get<GameCategory[]>(`/api/odds/bettable-categories${query}`)
}

export const getBettableLeagues = async (
  sportId: number,
  categoryId: number,
  startDate: string,
  endDate: string,
  languageId: number,
): Promise<League[]> => {
  let query = '?'
  query += 'sportId=' + sportId
  query += '&categoryId=' + categoryId
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&languageId=' + languageId

  return await AxiosClient.get<League[]>(`/api/odds/bettable-leagues${query}`)
}

export const useGamePeriods = (languageId: number): GamePeriod[] => {
  let query = '?languageId=' + languageId
  const { data, error } = useSWRImmutable<GamePeriod[]>(
    languageId ? '/api/odds/game-periods' + query : null,
    defaultFetcher,
  )
  handleHttpErrorStatus(error?.status)
  return data
}

export const useMarketTypes = (languageId: number): { marketTypes: MarketTypeDTO[]; isValidating: boolean } => {
  let query = '?languageId=' + languageId
  const { data, error, isValidating } = useSWR<MarketTypeDTO[]>(
    languageId ? '/api/odds/market-types' + query : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return { marketTypes: data, isValidating }
}

export const useMarketTypeCategories = (languageId: number): MarketTypeCategoryDTO[] => {
  let query = '?languageId=' + languageId
  const { data, error } = useSWR<MarketTypeCategoryDTO[]>(
    languageId ? '/api/odds/market-type-categories' + query : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60 * 60 * 1000, // 1h
    },
  )

  handleHttpErrorStatus(error?.status)

  return data
}

export const useEventCards = (
  languageId: number,
): {
  eventCards: EventCard[]
  mutateEventCards: (data?: EventCard[], shouldRevalidate?: boolean) => Promise<any>
  isValidating: boolean
} => {
  let query = '?languageId=' + languageId
  const { data, mutate, error, isValidating } = useSWR<EventCard[]>(
    languageId ? '/api/odds/event-cards' + query : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60000,
    },
  )

  handleHttpErrorStatus(error?.status)

  return { eventCards: data, mutateEventCards: mutate, isValidating }
}

export const useEventCardDetails = (
  languageId: number,
  cardId: number,
  marketTypeIds: number[] = [],
): {
  eventCard: EventCard
  mutateEventCard: (data?: EventCard, shouldRevalidate?: boolean) => Promise<any>
  isValidating: boolean
} => {
  let query = '?languageId=' + languageId
  query += '&cardId=' + cardId
  if (marketTypeIds?.length > 0) query += '&marketTypeIds=' + marketTypeIds.join(',')

  const { data, mutate, error, isValidating } = useSWR<EventCard>(
    languageId ? '/api/odds/event-card-details' + query : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60000,
    },
  )

  handleHttpErrorStatus(error?.status)

  return { eventCard: data, mutateEventCard: mutate, isValidating }
}

export const getEventCardDetails = async (
  req: IncomingMessage,
  session: Session,
  cardId: number,
  marketTypeIds: number[] = [],
) => {
  const oddsClient = useOddsClient()
  const sessionId = session.get('sessionId')
  const token = session.get('token')
  const languageId = session.get('languageId')
  const sessionData = buildSessionData(req, sessionId, token)

  return await oddsClient.cards.getCardById(sessionData, cardId, languageId, marketTypeIds)
}

export const useNextLeagueEvents = (
  leagueId: number,
  languageId: number,
  gameCount: number,
  startDate: string,
  endDate: string,
): {
  eventData: any
  mutateEvent: (data?: any, shouldRevalidate?: boolean) => Promise<any>
  isValidating: boolean
} => {
  let query = '?'
  query += 'leagueId=' + leagueId
  query += '&languageId=' + languageId
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&gameMode=' + GameMode.PreMatch
  query += '&onlyTop=' + false
  query += '&pageNumber=' + 0
  query += '&pageSize=' + gameCount
  query += '&marketTypeIds=' + [1, 175].join(',')

  const { data, mutate, error, isValidating } = useSWR<any>(
    languageId && leagueId ? '/api/odds/games-by-leagueid' + query : null,
    defaultFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 60000,
    },
  )

  handleHttpErrorStatus(error?.status)

  const result = data
    ? {
        gameCategoryId: data?.gameCategoryId,
        gameCategoryName: data?.gameCategoryName,
        gameCategoryShortSign: data?.gameCategoryShortSign,
        leagueName: data?.leagueName,
        sportId: data?.sportId,
        sportName: data?.sportName,
        sportShortSign: data?.sportShortSign,
        events: data?.items?.filter((event: Game) => event.type === GameType.OneOnOne) ?? [],
      }
    : null

  return { eventData: result, mutateEvent: mutate, isValidating }
}
