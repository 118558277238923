import { useLayoutContext } from '../context/layout.context'
import { isNullOrEmpty } from '../util/string'
import useSportradarWidgetSettings, {
  lmtDefaultTabs,
  lmtTabsWithoutHeadToHead,
  SirBetAssistProps,
  SirHeadToHeadProps,
  SirLmtProps,
  SirTournamentPreviewProps,
} from './useSportradarWidgetSettings'

const useSportradarWidget = () => {
  const layoutContext = useLayoutContext()
  const { lmtEnabled, headToHeadEnabled, betAssistEnabled, tournamentPreviewEnabled } = useSportradarWidgetSettings()

  const addMatchtracker = (
    containerId: string,
    matchId: string,
    onError?: () => void,
    onTrack?: (param1: string, param2: any) => any
  ): boolean => {
    if (typeof SIR === 'undefined' || !lmtEnabled) return

    const handleOnTrack = (param1: string, param2: any) => {
      if (param1 === 'license_error' || !isNullOrEmpty(param2?.error)) onError()
      if (onTrack) onTrack(param1, param2)
    }

    SIR('addWidget', '#' + containerId, 'match.lmtPlus', {
      ...lmtOptions,
      tabs: headToHeadEnabled ? lmtTabsWithoutHeadToHead.tabs : lmtDefaultTabs.tabs,
      matchId: parseInt(matchId?.replace('sr:match:', '')),
      onTrack: handleOnTrack,
    })
  }

  const updateMatchtracker = (containerId: string, matchId: string, optionsToUpdate: Partial<SirLmtProps>) => {
    if (typeof SIR === 'undefined') return

    SIR('updateWidget', '#' + containerId, {
      ...lmtOptions,
      ...optionsToUpdate,
      matchId: parseInt(matchId?.replace('sr:match:', '')),
    })
  }

  const removeWidget = (containerId: string) => {
    if (typeof SIR === 'undefined') return

    SIR('removeWidget', '#' + containerId)
  }

  const addBetAssist = (containerId: string, matchId: string, market: string = '3Way') => {
    if (typeof SIR === 'undefined' || !betAssistEnabled) return

    SIR('addWidget', '#' + containerId, 'betAssist.standalone', {
      matchId: parseInt(matchId?.replace('sr:match:', '')),
      market,
    })
  }

  const addBetAssistOverlay = (containerId: string, matchId: string, market: string = '3Way') => {
    if (typeof SIR === 'undefined' || !betAssistEnabled) return

    SIR('addWidget', '#' + containerId, 'betAssist.button', {
      matchId: parseInt(matchId?.replace('sr:match:', '')),
      market,
    })
  }

  const addHeadToHead = (containerId: string, matchId: string) => {
    if (typeof SIR === 'undefined' || !headToHeadEnabled) return

    SIR('addWidget', '#' + containerId, 'headToHead.standalone', {
      ...headToHeadOptions,
      matchId: parseInt(matchId?.replace('sr:match:', '')),
    })
  }

  const addHeadToHeadOverlay = (containerId: string, matchId: string) => {
    if (typeof SIR === 'undefined' || !headToHeadEnabled) return

    SIR('addWidget', '#' + containerId, 'headToHead.button', {
      ...headToHeadOptions,
      matchId: parseInt(matchId?.replace('sr:match:', '')),
    })
  }

  const addTournamentPreview = (containerId: string, uniqueTournamentId: string) => {
    if (typeof SIR === 'undefined' || !tournamentPreviewEnabled) return

    SIR('addWidget', '#' + containerId, 'tournament.preview', {
      ...tournamentPreviewOptions,
      isMobile: layoutContext?.currentBreakPoint === 'mobile',
      uniqueTournamentId: parseInt(uniqueTournamentId?.replace('sr:tournament:', '')),
    })
  }

  return {
    addMatchtracker,
    updateMatchtracker,
    addBetAssist,
    addBetAssistOverlay,
    addHeadToHead,
    addTournamentPreview,
    removeWidget,
    addHeadToHeadOverlay,
  }
}

export default useSportradarWidget

declare function SIR(action: 'addWidget', containerId: string, widgetType: 'match.lmt', props: SirLmtProps)
declare function SIR(action: 'addWidget', containerId: string, widgetType: 'match.lmtPlus', props: SirLmtProps)
declare function SIR(
  action: 'addWidget',
  containerId: string,
  widgetType: 'betAssist.standalone' | 'betAssist.button',
  props: SirBetAssistProps
)
declare function SIR(
  action: 'addWidget',
  containerId: string,
  widgetType: 'headToHead.standalone' | 'headToHead.button',
  props: SirHeadToHeadProps
)
declare function SIR(
  action: 'addWidget',
  containerId: string,
  widgetType: 'tournament.preview',
  props: SirTournamentPreviewProps
)
declare function SIR(action: 'updateWidget', containerId: string, props: SirLmtProps)
declare function SIR(action: 'removeWidget', containerId: string)

const lmtOptions: Partial<SirLmtProps> = {
  branding: { tabs: { option: 'iconText', iconPosition: 'start', variant: 'fullWidth' } },
  pitchCustomBgColor: '#5C750B',
  scoreboard: 'extended',
  detailedScoreboard: 'disable',
  tabsPosition: 'top',
  collapseTo: 'scoreboard',
  expanded: true,
  silent: true,
  layout: 'double',
  tabs: lmtDefaultTabs,
}

const headToHeadOptions: Partial<SirHeadToHeadProps> = {
  layout: 'inline',
}

const tournamentPreviewOptions: Partial<SirTournamentPreviewProps> = {
  disableEventTicker: true,
  disableMoreStats: true,
  isExpanded: true,
  disableCompare: true,
}
