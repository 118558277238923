import useTranslation from 'next-translate/useTranslation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons'

const Tag = ({
  label = null,
  backgroundColor = 'bg-gray-200',
  textColor = 'text-black',
  className = '',
  children = null,
}) => {
  return (
    <div
      className={`tag flex justify-center items-center h-[16px] px-1 rounded-sm uppercase !text-xxs font-semibold whitespace-nowrap ${backgroundColor} ${textColor} ${className}`}
    >
      {label && <span className="tag-label">{label}</span>}
      {children && children}
    </div>
  )
}

export const BetBuilderTag = ({ useShortSign = false }) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={`bet-builder-tag flex justify-center items-center space-x-1 shrink-0 h-4 px-1 rounded-sm !text-xxs bg-primary text-primaryContrast`}
    >
      <span className="bet-builder-tag-label uppercase">
        {useShortSign ? <FontAwesomeIcon icon={faPenToSquare} /> : t('Common.betBuilder')}
      </span>
    </div>
  )
}

export default Tag
