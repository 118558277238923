import { Bet } from '@arland-bmnext/api-data'
import { motion } from 'framer-motion'
import { Accordion } from '../../../animations/common'
import { BetListItemExpandedAreaItem } from './BetListItemExpandedAreaItem'

export const BetListItemExpandedArea = ({ bet }: { bet: Bet }) => {
  return (
    <motion.div
      className="bet-list-item-expanded-area flex flex-col w-full"
      variants={Accordion}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      <div
        className="bet-list-item-expanded-area-items flex flex-col space-y-2 pt-2 mt-2 w-full border-t-[1px] border-neutral">
        {bet && (
          <>
            {bet?.items?.map((betItem) => {
              return <BetListItemExpandedAreaItem betItem={betItem} key={betItem.id} />
            })}
          </>
        )}
      </div>
    </motion.div>
  )
}
